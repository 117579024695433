module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TWMK6JM","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LHNG61QXSG"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1044850272330887"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://backend.eurobath.co.za/graphql","verbose":true,"html":{"useGatsbyImage":false,"createStaticFiles":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"schema":{"perPage":40,"requestConcurrency":40,"timeout":600000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"develop":{"hardCacheMediaFiles":true,"hardCacheData":false,"nodeUpdateInterval":1200000},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"type":{"Product":{},"PostFormat":{"exclude":true},"Comment":{"exclude":true},"ProductTag":{"exclude":true},"Customer":{"exclude":true},"Coupon":{"exclude":true},"ShippingClass":{"exclude":true},"Refund":{"exclude":true},"Order":{"exclude":true},"PaTileBodyType":{"excludeFieldNames":["products","variations"]},"PaColour":{"excludeFieldNames":["products","variations"]},"PaSize":{"excludeFieldNames":["products","variations"]},"PaFinish":{"excludeFieldNames":["products","variations"]},"PaActuators":{"excludeFieldNames":["products","variations"]},"PaMaterial":{"excludeFieldNames":["products","variations"]},"UserRole":{"exclude":true},"PaymentGateway":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Euro Bath and Tiles","short_name":"EuroBath","start_url":"/","background_color":"#000000","theme_color":"#5D5D28","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8f35faa5280ad5288873af2753f22470"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"exclude":["/404/","/checkout/"],"trailingSlashes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#FFCF01","showSpinner":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
